<template>
    <div class="container" :class="{'kk_lang': i18n().locale === 'kk'}">
      <transition name="fade">
        <card-modal v-if="showModal" @toggleModal="toggleModal"/>
      </transition>
      <transition name="fade">
      <div v-show="showCardSlider" class="card-slider__layout" @click="showCardSlider = false">
        <div class="card-slider" @click="showCardSlider = false">
          <div class="card-slider__close" @click="showCardSlider = false">
            <img src="@/assets/images/close.svg" alt="close">
          </div>
          <div class="card-slider__cards" ref="cardSlider" @touchstart="touchStartMethod">
            <div class="card-slider__card" :class="{'active': activeCardId === 1}" @click.stop @click="activeCardId = 1">
              <img src="@/assets/images/cards/card-1.png" alt="joker">
            </div>
            <div class="card-slider__card" :class="{'active': activeCardId === 2}" @click.stop @click="activeCardId = 2">
              <img src="@/assets/images/cards/card-2.png" alt="batman">
            </div>
            <div class="card-slider__card" :class="{'active': activeCardId === 3}" @click.stop @click="activeCardId = 3">
              <img src="@/assets/images/cards/card-3.png" alt="harley">
            </div>
            <div class="card-slider__card" :class="{'active': activeCardId === 4}" @click.stop @click="activeCardId = 4">
              <img src="@/assets/images/cards/card-4.png" alt="superman">
            </div>
          </div>
          <div class="card-slider__info" @touchstart="touchStartMethod" @click.stop>
            <h5>{{ activeCard.name }}</h5>
            <p v-html="$t(activeCard.description)"></p>
<!--            <div class="card-slider__btns">
              <div class="card-slider__prev" :class="{'disabled': prevDisabled}" @click="prevCard">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 8L12 16L20 24" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div class="card-slider__next" :class="{'disabled': nextDisabled}" @click="nextCard">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 8L20 16L12 24" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>-->
            <a :href="'https://bankffin.kz/'+i18n().locale+'/res/mobile-app'" class="get-card__btn-border">
              <div class="get-card__btn">
                {{ $t('global.get-card') }}
              </div>
            </a>
          </div>
        </div>
      </div>
      </transition>
      <div class="banner">
        <div class="banner-info">
          <div class="banner-info__glow"></div>
          <div class="banner-info__stroke"></div>
          <div class="banner-info__text">
            <span>{{ $t('banner.text') }}</span>
          </div>
        </div>
        <h1 v-html="$t('banner.title')"></h1>
        <h3 v-html="$t('banner.subtitle')"></h3>
        <div class="banner-logos">
          <div class="banner-logos__item">
            <img src="@/assets/images/dc-logos/dc.png" alt="dc">
          </div>
          <div class="banner-logos__item">
            <img src="@/assets/images/dc-logos/superman.png" alt="superman">
          </div>
          <div class="banner-logos__item">
            <img src="@/assets/images/dc-logos/batman.png" alt="batman">
          </div>
          <div class="banner-logos__item">
            <img src="@/assets/images/dc-logos/batman-2.png" alt="batman">
          </div>
        </div>
      </div>

      <div class="card-block">
        <div class="cards">
          <div class="cards__wrapper">
            <div class="card" @click="selectCard(1)">
              <img src="@/assets/images/cards/card-1.png" alt="joker">
            </div>
            <div class="card" @click="selectCard(2)">
              <img src="@/assets/images/cards/card-2.png" alt="batman">
            </div>
            <div class="card" @click="selectCard(3)">
              <img src="@/assets/images/cards/card-3.png" alt="harley">
            </div>
            <div class="card" @click="selectCard(4)">
              <img src="@/assets/images/cards/card-4.png" alt="superman">
            </div>
          </div>
        </div>
        <div class="cards-logos">
          <img src="@/assets/images/mastercard.png" alt="mastercard">
          <img src="@/assets/images/visa.png" alt="visa">
        </div>
        <div class="cards-info">
          <img src="@/assets/images/coin.png" alt="coin">
          <p>{{ $t('cards.text') }}</p>
          <a :href="'https://bankffin.kz/'+i18n().locale+'/res/mobile-app'" class="get-card__btn-border">
            <div class="get-card__btn">
              {{ $t('global.get-card') }}
            </div>
          </a>
        </div>
      </div>

      <div class="originality content">
        <h2 v-html="$t('originality.title')"></h2>
        <div class="originality__content">
          <div class="originality__content-top">
            <div class="originality__text-border">
              <div class="originality__text">
                <div class="originality__gradient"></div>
                <h4>{{ $t('originality.text.title') }}</h4>
                <p>{{ $t('originality.text.subtitle') }}</p>
                <div class="originality__text-notice">
                  {{ $t('originality.text.notice') }}
                </div>
                <video autoplay muted loop playsinline preload="auto" style="pointer-events: none;">
                  <source v-if="isSafari" :src="dcMovVideo" type="video/mp4">
                  <source src="../assets/video/dc_card.webm" type="video/webm">
                </video>
              </div>
            </div>
            <div class="originality__qr-border">
              <div class="originality__qr">
                <div class="originality__qr-top">
                  <img src="@/assets/images/originality/logo.png" alt="logo">
                  <p>{{ $t('originality.qr.open-card') }}</p>
                </div>
                <div class="originality__qr-bottom">
                  <img src="@/assets/images/qr.png" alt="qr code">
                  <p>{{ $t('originality.qr.scan') }}</p>
                </div>
                <div class="originality__qr-mobile-btns">
                  <a :href="'https://bankffin.kz/'+i18n().locale+'/res/mobile-app'" target="_blank" class="originality__qr-mobile-btn">
                    <img src="@/assets/images/app-store.svg" alt="app store">
                    <span>App Store</span>
                  </a>
                  <a :href="'https://bankffin.kz/'+i18n().locale+'/res/mobile-app'" target="_blank" class="originality__qr-mobile-btn">
                    <img src="@/assets/images/google-play.svg" alt="play market">
                    <span>Play Market</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="originality__content-bottom">All DC characters and elements © & ™ DC and WBEI (s24)</div>
        </div>
      </div>

      <div class="benefit content">
        <h2 v-html="$t('benefit.title')"></h2>
        <div class="benefit__content">
          <div class="benefit__left">
            <div class="benefit__gradient"></div>
            <h4>{{ $t('benefit.content.title1') }}</h4>
            <p>{{ $t('benefit.content.subtitle1') }}</p>
            <div class="benefit__btn-wrapper">
              <div class="benefit__btn" @click="toggleModal(true)">{{ $t('benefit.content.btn') }}</div>
            </div>
          </div>
          <div class="benefit__right">
            <div class="benefit__right-top">
              <div class="benefit__gradient"></div>
              <h4>{{ $t('benefit.content.title2') }}</h4>
              <p>{{ $t('benefit.content.subtitle2') }}</p>
            </div>
            <div class="benefit__right-bottom">
              <div class="benefit__right-bottom__left">
                <div class="benefit__gradient"></div>
                <h4>{{ $t('benefit.content.title3') }}</h4>
                <p>{{ $t('benefit.content.subtitle3') }}</p>
              </div>
              <div class="benefit__right-bottom__right">
                <div class="benefit__gradient"></div>
                <h4>{{ $t('benefit.content.title4') }}</h4>
                <p>{{ $t('benefit.content.subtitle4') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="benefit__mobile">
          <VueSlickCarousel
              ref="benefitSlider"
              class="benefit-slider"
              :focusOnSelect="true"
              v-bind="benefitSliderSettings"
          >
            <div class="benefit__mobile-slide">
              <h4>{{ $t('benefit.content.title1') }}</h4>
              <p>{{ $t('benefit.content.subtitle1') }}</p>
              <div class="benefit__btn-wrapper">
                <div class="benefit__btn" @click="toggleModal(true)">{{ $t('benefit.content.btn') }}</div>
              </div>
            </div>
            <div class="benefit__mobile-slide">
              <h4>{{ $t('benefit.content.title2') }}</h4>
              <p>{{ $t('benefit.content.subtitle2') }}</p>
            </div>
            <div class="benefit__mobile-slide">
              <h4 v-html="$t('benefit.content.title3-mobile')"></h4>
              <p>{{ $t('benefit.content.subtitle3') }}</p>
            </div>
            <div class="benefit__mobile-slide">
              <h4>{{ $t('benefit.content.title4') }}</h4>
              <p>{{ $t('benefit.content.subtitle4') }}</p>
            </div>
          </VueSlickCarousel>
        </div>
        <div class="benefit__info">
          <img src="@/assets/images/info-icon.svg" alt="i">
          <span>{{ $t('benefit.info') }}</span>
        </div>
      </div>

      <div class="instruction content">
        <h2 v-html="$t('instruction.title')"></h2>
        <div class="instruction__content">
          <div class="instruction__items">
            <div
                class="instruction__item-border"
                :class="{'active': instruction.id === currentInstructionId}"
                @mouseover="currentInstructionId = instruction.id"
                v-for="instruction in instructions"
                :key="instruction.id"
            >
              <div class="instruction__item">
                <div class="instruction__item-number">
                  <span>{{ instruction.id }}</span>
                </div>
                <div class="instruction__item-text">
                  <h5>{{ $t(instruction.title) }}</h5>
                  <p v-if="instruction.description">{{ $t(instruction.description) }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="instruction__img">
            <img :src="require(`@/assets/images/instruction/${currentInstruction.img}_${i18n().locale}.png`)" alt="">
          </div>
        </div>
        <div class="instruction__mobile">
          <VueSlickCarousel
              ref="imgSlider"
              class="img-slider"
              :asNavFor="textSlider"
              :focusOnSelect="true"
              v-bind="imgSliderSettings"
          >
            <div
                v-for="instruction in instructions"
                :key="instruction.id"
                class="instruction__mobile-img">
              <img :src="require(`@/assets/images/instruction/${instruction.img}_${i18n().locale}.png`)" alt="">
            </div>
          </VueSlickCarousel>
          <VueSlickCarousel
              ref="textSlider"
              class="text-slider"
              :asNavFor="imgSlider"
              :focusOnSelect="true"
              v-bind="textSliderSettings"
          >
            <div
                v-for="instruction in instructions"
                :key="instruction.id"
                class="slider-text-item__border"
            >
              <div class="slider-text-item">
                <div class="slider-text-item__count">
                  <span>{{ instruction.id }}</span>
                </div>
                <h5>{{ $t(instruction.title) }}</h5>
                <p v-if="instruction.description">{{ $t(instruction.description) }}</p>
              </div>
            </div>
          </VueSlickCarousel>
        </div>
      </div>

      <div class="qr">
        <div class="qr-text">
          <h3>{{ $t('qr.title') }}</h3>
          <p>{{ $t('qr.subtitle') }}</p>
          <div class="qr-block__wrapper">
            <div class="qr-block">
              <img src="@/assets/images/qr.png" alt="qr">
              <div class="qr-block__text">{{ $t('qr.block-text') }}</div>
            </div>
          </div>
          <div class="qr-buttons">
            <a :href="'https://bankffin.kz/'+i18n().locale+'/res/mobile-app'" target="_blank" class="qr-btn">
              <img src="@/assets/images/app-store.svg" alt="app store">
              <span>App Store</span>
            </a>
            <a :href="'https://bankffin.kz/'+i18n().locale+'/res/mobile-app'" target="_blank" class="qr-btn">
              <img src="@/assets/images/google-play.svg" alt="play market">
              <span>Play Market</span>
            </a>
          </div>
        </div>
      </div>

      <div class="faq content">
        <h2 v-html="$t('faq.title')"></h2>
        <div class="faq-list">
          <faq-item
              :header="$t('faq.item1.header')"
              :content="$t('faq.item1.content')">
          </faq-item>
          <faq-item
              :header="$t('faq.item2.header')"
              :content="$t('faq.item2.content')">
          </faq-item>
          <faq-item
              :header="$t('faq.item3.header')"
              :content="$t('faq.item3.content')">
          </faq-item>
          <faq-item
              :header="$t('faq.item4.header')"
              :content="$t('faq.item4.content')">
          </faq-item>
          <faq-item
              :header="$t('faq.item5.header')"
              :content="$t('faq.item5.content')">
          </faq-item>
          <faq-item
              :header="$t('faq.item6.header')"
              :content="$t('faq.item6.content')">
          </faq-item>
        </div>
      </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import FaqItem from "@/components/FaqItem.vue";
import CardModal from "@/components/CardModal.vue";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import dcMovVideo from '@/assets/video/dc_card.mov';

export default {
  name: "HomeView",
  data() {
    return {
      isIOSorMacOS: /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform),
      propositionInfo: false,
      aboutInfo: false,
      showModal: false,
      currentInstructionId: 1,
      dcMovVideo,
      instructions: [
        {
          id: 1,
          title: 'instruction.title1',
          description: 'instruction.subtitle1',
          img: "1"
        },
        {
          id: 2,
          title: 'instruction.title2',
          description: 'instruction.subtitle2',
          img: "2"
        },
        {
          id: 3,
          title: 'instruction.title3',
          description: 'instruction.subtitle3',
          img: "3"
        },
        {
          id: 4,
          title: 'instruction.title4',
          description: 'instruction.subtitle4',
          img: "4"
        },
        {
          id: 5,
          title: 'instruction.title5',
          img: "5"
        },
      ],
      benefitSliderSettings: {
        "dots": true,
        "arrows": false,
        "edgeFriction": 0.35,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 1,
      },
      imgSlider: undefined,
      textSlider: undefined,
      imgSliderSettings: {
        "dots": false,
        "arrows": false,
        "edgeFriction": 0.35,
        "infinite": false,
        "speed": 500,
        "fade": true,
        "slidesToShow": 1,
      },
      textSliderSettings: {
        "dots": true,
        "arrows": false,
        "edgeFriction": 0.35,
        "infinite": false,
        "speed": 500,
        "fade": true,
        "slidesToShow": 1,
      },
      activeCardId: 1,
      cardSlider: undefined,
      showCardSlider: false,
      cards: [
        {
          id: 1,
          name: 'Joker',
          description: 'cards.card-1'
        },
        {
          id: 2,
          name: 'The Batman',
          description: 'cards.card-2'
        },
        {
          id: 3,
          name: 'Harley Quinn',
          description: 'cards.card-3'
        },
        {
          id: 4,
          name: 'Superman',
          description: 'cards.card-4'
        }
      ]
    }
  },
  computed: {
    currentInstruction() {
      return this.instructions.find(item => item.id === this.currentInstructionId);
    },
    activeCard() {
      if (this.cardSlider) {
        this.cardSlider.style.setProperty('--position', this.activeCardId)
      }

      return this.cards.find(card => card.id === this.activeCardId);
    },
    prevDisabled() {
      return this.activeCardId === 1
    },
    nextDisabled() {
      return this.activeCardId === this.cards.length
    },
    isSafari() {
      let userAgentString = navigator.userAgent;
      let chromeAgent     = userAgentString.indexOf("Chrome") > -1;
      let safariAgent     = userAgentString.indexOf("Safari") > -1;

      if ((chromeAgent) && (safariAgent)) safariAgent = false;

      return safariAgent;
    }
  },
  components: {
    CardModal,
    FaqItem,
    VueSlickCarousel
  },
  mounted() {
    let self = this
    setTimeout(function () {
      self.imgSlider = self.$refs.imgSlider;
      self.textSlider = self.$refs.textSlider;
      self.cardSlider = self.$refs.cardSlider;
    }, 100)

    window.onpageshow = function() {
      let videos = document.querySelectorAll('video');

      videos.forEach(function (video) {
        video.play()
      })
    };

    this.glowAnimation();
  },
  watch: {
    showCardSlider(val) {
      if (val) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    },
    showModal(val) {
      if (val) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    }
  },
  methods: {
    i18n() {
      return i18n
    },
    toggleModal(data) {
      this.showModal = data;
    },
    glowAnimation() {
      let x = 11,
          y = 0,
          valueX = 1,
          valueY = 2;

      let glow = document.querySelector('.banner-info__glow'),
          stroke = document.querySelector('.banner-info__stroke');

      setInterval(function () {
        if (x === 90 && y < 100) {
          valueX = -1;
          y += valueY;
        } else if (x === 10 && y > 0) {
          valueX = 1;
          y += valueY;
        } else if (y === 100) {
          valueY = -2;
          x += valueX;
        } else if (y === 0) {
          valueY = 2;
          x += valueX;
        }

        glow.style.background = 'radial-gradient(25% 40% at '+x+'% '+y+'%, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%)';
        stroke.style.background = 'radial-gradient(18.3344% 45.3822% at '+x+'% '+y+'%, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%)';
      }, 20)
    },
    prevCard() {
      if (!this.prevDisabled) {
        this.activeCardId--;
      }
    },
    nextCard() {
      if (!this.nextDisabled) {
        this.activeCardId++;
      }
    },
    selectCard(id) {
      this.activeCardId = id
      this.showCardSlider = true;
    },
    touchStartMethod (touchEvent) {
      if (touchEvent.changedTouches.length !== 1) {
        return;
      }
      const posXStart = touchEvent.changedTouches[0].clientX;
      addEventListener('touchend', (touchEvent) => this.touchEndMethod(touchEvent, posXStart), {once: true});
    },
    touchEndMethod (touchEvent, posXStart) {
      if (touchEvent.changedTouches.length !== 1) {
        return;
      }
      const posXEnd = touchEvent.changedTouches[0].clientX;
      if (posXStart < posXEnd) {
        this.prevCard();
      } else if (posXStart > posXEnd) {
        this.nextCard();
      }
    }
  }
}

</script>
